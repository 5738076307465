var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"all_main_wrap"},[_c('div',{staticClass:"all_header_box"},[_c('div',{staticClass:"sider_left"},[_c('span',{staticClass:"title"},[_vm._v("证书管理")]),_c('div',{staticClass:"crumbs"},[_c('router-link',{staticClass:"crumbs_item",attrs:{"tag":"a","to":"/admin/Certificate/Examination"}},[_vm._v("考试证书 / ")]),_c('span',{staticClass:"crumbs_item crumbs_last"},[_vm._v("获证人员")])],1)]),_c('div',{staticClass:"sider_right"},[_c('a-input',{attrs:{"placeholder":"请输入关键字"},model:{value:(_vm.keyWord),callback:function ($$v) {_vm.keyWord=$$v},expression:"keyWord"}}),_c('a-button',{staticClass:"btn",attrs:{"type":"primary"},on:{"click":_vm.search}},[_vm._v("搜索")])],1)]),_c('div',{staticClass:"table-box"},[_c('a-table',{staticClass:"table-template",attrs:{"rowKey":function (item) { return item.id; },"columns":_vm.columns,"data-source":_vm.tableData,"pagination":{
        total: _vm.total,
        current: _vm.pageNumber,
        defaultPageSize: _vm.pageSize,
        showSizeChanger: true,
        showTotal: function (total, range) {
          return ("共" + total + "条");
        },
      }},on:{"change":_vm.onPage},scopedSlots:_vm._u([{key:"index",fn:function(item, row, i){return [_c('div',{staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s(i + 1)+" ")])]}},{key:"operation",fn:function(text, record){return [_c('div',{staticStyle:{"position":"relative","overflow":"hidden"}},[_c('a',[_vm._v("查看")]),_c('viewer',[_c('img',{staticClass:"inneer_img",staticStyle:{"position":"absolute","top":"-10px","left":"0","opacity":"0","cursor":"pointer"},attrs:{"src":record.picture}})])],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }